import moment from 'moment'
import store from 'store'
import AuthService from './authService'
import history from './history'
import { NotificationManager } from './NotificationsManager'
import { URLManager } from './URLManager'
import { getUnreadNotifications } from 'data/business/UnreadNotifications/thunks'
import { getShifts } from 'data/business/Shifts/thunks'
import { ClearCurrentlyActiveShiftsReducer } from 'data/business/CurrentlyActiveShifts/actions'
import { getCurrentlyActiveShiftsWithClockedInFetch } from 'data/business/CurrentlyActiveShifts/thunks'
import { ENV_NAME } from 'config/envVariables'

// TTL
const BUSINESS_ENTITY_CHECK_TTL = 1
// 48 hours
const NOTIFICATION_CHECK_TTL = 1
const CURRENTLY_CLOCKED_IN_CHECK_TTL = 5

/**
 *
 * @param {*} param0
 */
export const capitalize = ([first, ...rest]) => {
  const string = `${first.toUpperCase()}${rest.join('').toLowerCase()}`
  return string.replace(/_/g, ' ')
}
/**
 * Retry loading on chunk
 */

export const retry = (func, retriesLeft = 5, interval = 1000) =>
  new Promise((resolve, reject) => {
    func()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
            return
          }
          retry(func, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })

const getShiftAllowedParams = () => {
  const params = [
    'search',
    'page',
    'sort_by',
    'date_start',
    'date_end',
    'to_hire',
    'type',
    'auto_accept',
    'favorite',
    'price_end',
    'price_start',
    'lng',
    'lat',
    'distance',
    'distance_in',
    'progress',
    'filled',
    'progress',
    'status',
    'property_id',
    'training_mode',
    'regions[]',
    'gig_types[]',
    'properties[]',
    'account_manager_id',
    'business_ids[]',
    'follow_by_user_ids[]',
    'industry_ids[]',
  ]
  return params
}

/**
 *
 * @param {*} urlSearchParams
 */
const getShiftsURLParams = urlSearchParams => {
  const params = new URLSearchParams(urlSearchParams)
  const urlAllowedParams = getShiftAllowedParams()
  // const params = {}
  const finalParams = {}
  for (const param of params) {
    // This is workaround for gig params that are array example gigTypes[] , properties[]...
    // this way we are creating our own array , we can't use for example gigTypes[] as key because the key when we send params
    // will be gigTypes[][] and we don't want that
    const key = param[0]
    const value = param[1]
    const allowedToSendToRequest =
      urlAllowedParams.includes(key) && value !== '-1' && value !== ''
    if (allowedToSendToRequest) {
      if (key.includes('[')) {
        const actualKey = [...key].slice(0, key.length - 2).join('')
        finalParams[actualKey] = finalParams[actualKey]
          ? [...finalParams[actualKey], value]
          : [value]
      } else finalParams[key] = value
    }
  }
  return finalParams
}

const getShiftParametars = (hasMore = false, currentPage = 0) => {
  const location = localStorage.getItem('location')
  const params = getShiftsURLParams(history.location.search)
  // check location
  if (location) {
    const { longitude, latitude, regionId } = JSON.parse(location)
    if (regionId) {
      params.regions = [regionId]
    } else {
      params.longitude = longitude
      params.latitude = latitude
    }
  }
  if (params.distance) {
    params.distance_in = 'miles'
  }
  if (hasMore) {
    const nextPage = currentPage + 1
    params.page = nextPage
  }
  return params
}

export const getShiftParamsNoPage = () => {
  const location = localStorage.getItem('location')
  const params = getShiftsURLParams(history.location.search)
  // check location
  if (location) {
    const { longitude, latitude, regionId } = JSON.parse(location)
    if (regionId) {
      params.regions = [regionId]
    } else {
      params.longitude = longitude
      params.latitude = latitude
    }
  }
  if (params.distance) {
    params.distance_in = 'miles'
  }
  return params
}

export const fetchShiftsBusiness = (
  specialParams = null,
  getFromUrl = true,
) => {
  let params
  if (getFromUrl) {
    params = getShiftParametars()
  }
  if (specialParams !== null) {
    params = { ...params, ...specialParams }
  }
  store.dispatch(getShifts({ params, preventConcatenate: true }))
}

export const fetchShiftsVirtualizationBusiness = () => {
  const params = getShiftParamsNoPage()
  store.dispatch(getShifts({ params, preventConcatenate: true }))
}

/**
 *
 * @param {*} name
 */
export const removeFromUrl = name => {
  const urlManager = new URLManager()
  urlManager.removeParamAndPush(name)
}

export const minutesDifferenceBetweenTwoDates = (dt2, dt1) => {
  const diff = dt2 - dt1
  return Math.floor(diff / 1000 / 60)
}

/**
 * Checks if business entity should be updated
 */
export const shouldBusinessEntityBeUpdated = () => {
  const businessEntityLastUpdate = localStorage.getItem(
    'businessEntityLastUpdate',
  )
  if (businessEntityLastUpdate) {
    const now = new Date()
    const minuteDifference = minutesDifferenceBetweenTwoDates(
      now.getTime(),
      businessEntityLastUpdate,
    )
    return minuteDifference > BUSINESS_ENTITY_CHECK_TTL
  }

  return true
}

const checkIfNotificationShouldBeUpdated = () => {
  const notification = localStorage.getItem('notification')
  if (notification) {
    const { timestamp } = JSON.parse(notification)
    const now = new Date()
    const minuteDifference = minutesDifferenceBetweenTwoDates(
      now.getTime(),
      timestamp,
    )
    return minuteDifference >= NOTIFICATION_CHECK_TTL
  }
  const currentDate = new Date()
  const notificationObject = {
    timestamp: currentDate.getTime(),
  }
  localStorage.setItem('notification', JSON.stringify(notificationObject))
  return true
}

/**
 * Checks if the business users clocked in upshifteres should be checked for any news
 * if so, then makes 2 api calls
 * 1. get gigs that are active
 * 2. gets all the active punch-cards for that shift
 */
const checkIfCurrentlyClockedInShouldBeUpdated = () => {
  const activeAndCurrentlyClockedIn = localStorage.getItem(
    'activeAndCurrentlyClockedIn',
  )
  if (activeAndCurrentlyClockedIn) {
    const { timestamp } = JSON.parse(activeAndCurrentlyClockedIn)
    const now = new Date()
    const minuteDifference = minutesDifferenceBetweenTwoDates(
      now.getTime(),
      timestamp,
    )
    return minuteDifference >= parseInt(CURRENTLY_CLOCKED_IN_CHECK_TTL, 10)
  }
  const currentDate = new Date()
  const notificationObject = {
    timestamp: currentDate.getTime(),
  }
  localStorage.setItem(
    'activeAndCurrentlyClockedIn',
    JSON.stringify(notificationObject),
  )
  return true
}

export const updateUnreadNotifications = shouldBypassUpdate => {
  const shouldUpdate = checkIfNotificationShouldBeUpdated()
  if (shouldUpdate || shouldBypassUpdate) {
    const currentDate = new Date()
    const notificationObject = {
      timestamp: currentDate.getTime(),
    }
    localStorage.setItem('notification', JSON.stringify(notificationObject))

    store.dispatch(getUnreadNotifications())
  }
}

/**
 * Updating the shift screen
 */
export const updateActiveShiftsAndCurrentlyClockedInUpshifters = initialFetch => {
  const businessEntity = AuthService.getBusinessEntity()
  const params = {
    progress: 1,
  }
  const isActiveLink = history.location.pathname.includes('/active_shift')
  const shouldUpdate = checkIfCurrentlyClockedInShouldBeUpdated()
  if ((shouldUpdate || !initialFetch) && !isActiveLink) {
    const currentDate = new Date()
    const timestampObject = {
      timestamp: currentDate.getTime(),
    }
    localStorage.setItem(
      'activeAndCurrentlyClockedIn',
      JSON.stringify(timestampObject),
    )
    store.dispatch(ClearCurrentlyActiveShiftsReducer())
    store.dispatch(
      getCurrentlyActiveShiftsWithClockedInFetch({
        ...params,
        business_id: businessEntity.id,
      }),
    )
  }
}

export const updateArray = (data, payload) => {
  const updatedArray = data.map(item => {
    if (item.id !== payload.id) {
      return item
    }
    return payload
  })
  return updatedArray
}

export const isObjectEmpty = arg => Object.entries(arg).length === 0

export const isMobile = () => window.innerWidth <= 576
export const findIfUpshifterHasCanceledNextWorkingDay = (
  multidayDays,
  canceledDays,
) => {
  const nextWorkingDay = multidayDays.find(day => {
    const today = new Date()
    const nextDay = new Date(day.date_start)
    if (nextDay > today && day.working_day) {
      // first date is in future, or it is today
      return day
    }
    return null
  })
  if (nextWorkingDay && Array.isArray(canceledDays)) {
    const nextCanceledDay = canceledDays.find(day => {
      if (nextWorkingDay.id === day.day_id) return true
      return false
    })
    return nextCanceledDay
  }
  return false
}

export const isTablet = () => window.innerWidth <= 768

/**
 * Copies a text to clipboard
 * @param textToCopy The text to copy to clipboard
 * @param notificationMessage Notification message to display on successful copy
 */
export const copyTextToClipboard = (
  textToCopy,
  notificationMessage = 'Copied to clipboard',
) => {
  const textField = document.createElement('textarea')
  textField.innerText = textToCopy
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
  NotificationManager.success(notificationMessage)
}

export const checkIfShiftIsWithin24hours = shift => {
  const currentBrowserTimezone = moment.tz.guess()
  const currentBrowserTime = moment().format()
  const timeStart = shift.time_start
  const shiftTimezone = shift.property_timezone
  const timeStartWithTimeZone = moment(timeStart).tz(shiftTimezone)
  const timeStartInShiftTimeZone = moment(timeStartWithTimeZone).tz(
    currentBrowserTimezone,
  )
  const duration = moment.duration(
    timeStartInShiftTimeZone.diff(currentBrowserTime),
  )
  const hoursDifference = duration.asHours()
  return hoursDifference <= 24
}

export const trackingEnvName = ENV_NAME

export const is2XS = () => window.innerWidth < 230

export const isXS = () => window.innerWidth >= 230 && window.innerWidth < 576

export const isSM = () => window.innerWidth >= 576 && window.innerWidth < 768

export const isMD = () => window.innerWidth >= 768 && window.innerWidth < 992

export const isLG = () => window.innerWidth >= 992 && window.innerWidth < 1024

export const isXL = () => window.innerWidth >= 1024 && window.innerWidth < 1300

export const is2XL = () => window.innerWidth >= 1300 && window.innerWidth < 1500

export const is3XL = () => window.innerWidth >= 1500 && window.innerWidth < 1700

export const is4XL = () => window.innerWidth >= 1700 && window.innerWidth < 1900

export const is5XL = () => window.innerWidth >= 1900
